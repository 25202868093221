import React, { Component } from 'react';
import image_check from "../image/icon-check.svg";

// Footer item image
function FooterItemImage(backgroundimageurl)
{
    let style = {
        backgroundImage: backgroundimageurl
    };

    return <div className="footer-configurator-dropdown-item-image" style={style}></div>
}

// Footer item border
function FooterItemBorder(show)
{
    if (show === true)
    {
        return <div className="footer-configurator-dropdown-item-border-selected">
        </div>; 
    }
    else
    {
        return <div className="footer-configurator-dropdown-item-border">
        </div>;
    }
}

// Footer item label
function FooterItemLabel(checkmark, str)
{
    return (
        <div className="footer-configurator-dropdown-item-label">
            {checkmark &&
                <img src={image_check} alt="checkmark" className="footer-configurator-dropdown-item-checkmark"/>
            }

            {str}
        </div>
    )
}

// Footer dropdown spacing types

class FooterDropdownSpacingType extends Component
{
    constructor(props)
    {
        // Props
        super(props);

        // State
        this.state = {
            opened: false,
        }
    }

    render()
    {
        if (this.props.content_spacing_types.length > 0
        && this.props.precompute_stone_name !== undefined)
        {
            // Dropdown
            const dropdown = [];
            for (let i = 0; i < this.props.content_spacing_types.length; i++)
            {
                dropdown.push(
                    <div className="footer-configurator-dropdown-content-items clearfix" key={i}>
                        <button className="footer-configurator-dropdown-item" onClick={() => this.props.function_configurator_spacing_type(i)}>
                            {FooterItemImage('url(../texture/preview/'+this.props.precompute_stone_name+'_'+this.props.content_spacing_types[i][0]+'_000000_halfsteensverband_h_preview.jpg)')}
                            {FooterItemBorder(this.props.configurator_spacing_type === i)}
                            {FooterItemLabel(this.props.configurator_spacing_type === i, this.props.content_spacing_types[i][1+this.props.translation_language_index])}
                        </button>
                    </div>
                );
            }

            return (
                <React.Fragment>
                    <div className="footer-configurator-dropdown">
                        <div className="footer-configurator-dropdown-button" id="button-spacing-type">
                            <div className="footer-configurator-dropdown-button-content">
                                <div className="footer-configurator-dropdown-description">{this.props.function_translate('configurator_spacing')}</div>

                                {this.props.content_spacing_types[this.props.configurator_spacing_type][1+this.props.translation_language_index]}
                            </div>
                        </div>
                        <div className="footer-configurator-dropdown-content">
                            {dropdown}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else
        {
            return (
                <div className="footer-configurator-dropdown">
                    <button className="footer-configurator-dropdown-button">
                    </button>
                    <div className="footer-configurator-dropdown-content">
                    </div>
                </div>
            )
        }
    }
}

// -----------------------------------------------------------------------

// Calculator

class FooterCalculator extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            form_type: '0',
            form_m2: '',
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event)
    {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event)
    {
        // Do nothing
        
        event.preventDefault();
    }

    render()
    {
        const form_type_dropdown = [];

        form_type_dropdown.push(
            <option key={0} value={0}>{this.props.function_translate('form_stones')}</option>
        );

        form_type_dropdown.push(
            <option key={1} value={1}>{this.props.function_translate('form_strips')}</option>
        );

        let footer_calculator_surface = '-';
        let footer_calculator_stones = '-';
        let footer_calculator_weight = '-';
        let footer_calculator_pallets = '-';

        if (this.state.form_m2 !== '')
        {
            let calc_stones = this.state.form_m2*this.props.content_spacing_types[this.props.configurator_spacing_type][7];

            let calc_weight = 0;
            if (this.state.form_type === '0')
                calc_weight = 5.10;
            else
                calc_weight = 1.06;
            
            let calc_weight_total = calc_stones*calc_weight;
            let str_calc_weight_total = parseFloat(calc_weight_total).toLocaleString();
            
            let calc_perpallet;
            if (this.state.form_type === '0')
                calc_perpallet = 216;
            else
                calc_perpallet = 520;

            let calc_pallets = parseInt(Math.ceil(calc_stones/calc_perpallet)).toLocaleString();

            footer_calculator_surface = parseInt(this.state.form_m2).toLocaleString()+' m²';

            footer_calculator_stones = '±'+parseInt(calc_stones).toLocaleString();

            if (this.state.form_type === '0')
                footer_calculator_weight = '±'+str_calc_weight_total+' kg (5,10 '+this.props.function_translate('footer_calculator_kg_per_stone')+')';
            else
                footer_calculator_weight = '±'+str_calc_weight_total+' kg (1,06 '+this.props.function_translate('footer_calculator_kg_per_strip')+')';

            footer_calculator_pallets = calc_pallets+' ('+calc_perpallet+' '+this.props.function_translate('footer_calculator_stones_per_pallet')+')';
        }

        // Pre-compute collection+stone name
        let precompute_stone_name = undefined;
        if (this.props.content_stone_collections.length > 0 && this.props.content_stone_types.length > 0)
        {
            precompute_stone_name = this.props.content_stone_collections[this.props.configurator_stone_collection][1]+'_'+this.props.content_stone_types[0][0];
        }

        return (
            <div className="footer-calculator">
                <div className="footer-text">
                    <div className="footer-title">
                        {this.props.function_translate('footer_calculate_amount')}
                    </div>

                    <form onSubmit={this.handleSubmit} className="form-container">
                        <div className="row">
                            <div className="col-12 col-sm-4 form-col-left">
                                <FooterDropdownSpacingType
                                    translation_language_index={this.props.translation_language_index}
                                    precompute_stone_name={precompute_stone_name}
                                    content_stone_collections={this.props.content_stone_collections}
                                    content_spacing_types={this.props.content_spacing_types}
                                    configurator_stone_collection={this.props.configurator_stone_collection}
                                    configurator_spacing_type={this.props.configurator_spacing_type}
                                    function_configurator_spacing_type={this.props.function_configurator_spacing_type}
                                    function_translate={this.props.function_translate} />
                            </div>

                            <div className="col-12 col-sm-4 form-col-center">
                                <select
                                    className = "form-singleline-dropdown"
                                    name = "form_type"
                                    value = {this.state.form_type}
                                    onChange = {this.handleInputChange}>
                                    
                                    {form_type_dropdown}
                                </select>
                            </div>

                            <div className="col-12 col-sm-4 form-col-right">
                                <input
                                    className = "form-singleline-value"
                                    name = "form_m2"
                                    type = "number"
                                    min = "0"
                                    value = {this.state.form_m2}
                                    onChange = {this.handleInputChange}
                                    placeholder = {this.props.function_translate('form_m2')} />
                            </div>
                        </div>
                    </form>

                    <div className="footer-configuration clearfix">
                        <div className="footer-column">
                            <div className="footer-text">
                                {this.props.function_translate('footer_calculator_surface')}
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-text-dark">
                                {footer_calculator_surface}
                            </div>
                        </div>
                        
                        <div className="footer-column">
                            <div className="footer-text">
                                {this.props.function_translate('footer_calculator_stones')}
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-text-dark">
                                {footer_calculator_stones}
                            </div>
                        </div>

                        <div className="footer-column">
                            <div className="footer-text">
                                {this.props.function_translate('footer_calculator_weight')}
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-text-dark">
                                {footer_calculator_weight}
                            </div>
                        </div>

                        <div className="footer-column">
                            <div className="footer-text">
                                {this.props.function_translate('footer_calculator_pallets')}
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-text-dark">
                                {footer_calculator_pallets}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// -----------------------------------------------------------------------

// Footer

class Footer extends Component
{
    render()
    {
        return (
            <div className="footer-container">
                <div className="row footer-align">
                    <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                        <FooterCalculator            
                            translation_language_index={this.props.translation_language_index}
                            content_stone_collections={this.props.content_stone_collections}
                            content_stone_types={this.props.content_stone_types}  
                            content_spacing_types={this.props.content_spacing_types}
                            configurator_stone_collection={this.props.configurator_stone_collection}
                            configurator_spacing_type={this.props.configurator_spacing_type}
                            function_configurator_spacing_type={this.props.function_configurator_spacing_type} 
                            function_translate={this.props.function_translate} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer